import wejo from "../images/WJ_logo.webp";
import imagine from "../images/IM_logo.webp";
import jack from "../images/JD_logo.webp";
import dhl from "../images/DHL_logo.webp";
import landg from "../images/LG_logo.webp";
import ratp from "../images/RA_logo.webp";
import gasg from "../images/GA_logo.webp";
import gal from "../images/GAL_logo.webp";

export const introduction = {
  title: "Michael Mikucki",
  section: "- Introduction",
  sectionTitle: "Senior Fullstack Engineer, based in Milton Keynes",
  description:
    "I'm Michael, software engineer focused on building high quality, custom websites, mobile and web applications.",
  button: "More about me",
  url: "/about",
};

export const about = {
  title: "About Me",
  section: "- More about me",
  sectionTitle: "Senior Fullstack Engineer, based in Milton Keynes",
  description:
    "My main goal is to understand your business needs, to create truly unique user experience, which will help take your business to the next level. My role doesn’t end there, with ongoing support and monitoring I can ensure your website is performing and continues to deliver great results.",
  button: "Close",
};

export const clients = {
  section: "- Clients",
  sectionTitle: "Companies I worked with",
  description:
    "Here are some of the companies I have recently worked with, both as a contractor and an in house developer",
  companies: [
    {
      name: "Wejo",
      siteUrl: "https://wejo.com",
      imageUrl: wejo,
    },
    {
      name: "Imagine Cruising",
      siteUrl: "https://imaginecruising.co.uk",
      imageUrl: imagine,
    },
    {
      name: "Jack Daniels",
      siteUrl: "https://jackdaniels.com",
      imageUrl: jack,
    },
    {
      name: "DHL",
      siteUrl: "https://dhl.com",
      imageUrl: dhl,
    },
    {
      name: "Legal & General",
      siteUrl: "https://legalandgeneral.com",
      imageUrl: landg,
    },
    {
      name: "Ratp Dev",
      siteUrl: "https://ratpdev.com",
      imageUrl: ratp,
    },
    {
      name: "Go Ahead Singapore",
      siteUrl: "https://go-aheadsingapore.com",
      imageUrl: gasg,
    },
    {
      name: "Go Ahead London",
      siteUrl: "https://goaheadlondon.com",
      imageUrl: gal,
    },
  ],
};

export const skills = {
  section: "- Skills",
  sectionTitle: "Skills and technologies",
  description:
    "Software development is rapidly changing, and regardless of technology I need to stay ahead of the curve to provide you with the best solution.",
  cards: [
    {
      temp: "main",
      title: "Frontend",
      description:
        "Technologies to create amazing user interfaces also provide great user experience, keeping in mind accessibility and budget.",
      skills: [
        "JavaScript",
        "HTML",
        "CSS / ScSS",
        "React",
        "NextJs",
        "Gatsby",
        "Styled Components",
        "Tailwind",
        "MaterialUI",
        "Bootstrap",
      ],
    },
    {
      temp: "side-1",
      title: "Backend & DB",
      description:
        "Technologies to create business logic and database interactions assuring high performance and security.",
      skills: [
        "JavaScript",
        "NodeJs",
        "ExpressJs",
        "NextJs",
        "PostgreSQL",
        "MongoDB",
        "MySql",
        "AWS",
        "AWS Cognito",
        "Rest API",
        "GraphQL",
      ],
    },
    {
      temp: "side-2",
      title: "Design",
      description:
        "Technologies to create beautiful look and feel of your application making sure users will return for the amazing experience.",
      skills: [
        "Figma",
        "Affinity Design",
        "Adobe XD",
        "Adobe Illustrator",
        "Wireframes",
        "Designs",
        "Prototypes",
      ],
    },
  ],
};

export const contact = {
  section: "- Contact",
  sectionTitle:
    "Have something exciting in mind or need help with an existing project?",
  description:
    "Let's work together to create game-changing experience and take your business to the next level.",
};
