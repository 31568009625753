import React from "react";
import {
  CallToActionLink,
  CallToActionButton,
  CallToActionExternalLink,
} from "./library";

const CallToAction = ({
  onClick,
  fontSize,
  buttonContent,
  extUrl,
  localUrl,
}) => {
  return typeof onClick === "function" ? (
    <CallToActionButton
      onClick={onClick}
      fontSize={fontSize}
      to={localUrl || "/"}
    >
      {buttonContent}
    </CallToActionButton>
  ) : extUrl ? (
    <CallToActionExternalLink fontSize={fontSize} href={extUrl}>
      {buttonContent}
    </CallToActionExternalLink>
  ) : (
    <CallToActionLink fontSize={fontSize} to={localUrl || "/"}>
      {buttonContent}
    </CallToActionLink>
  );
};

export default CallToAction;
