import React, { useState } from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  ContactFormContainer,
  ContactFormGroup,
  FormButton,
  InputField,
  InputTextArea,
  Label,
  ErrorMessage,
  AnimatedIcon,
  SvgTick,
} from "./library";
import Spinner from "./Spinner";

const ContactForm = () => {
  const [submitStatus, setSubmitStatus] = useState(true);

  function validateName(value) {
    let error;
    if (!value) {
      error = "Name is required";
    } else if (value.length < 2) {
      error = "Name must be at least 2 characters long";
    }
    return error;
  }

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Please enter valid email address";
    }
    return error;
  }

  function validateMessage(value) {
    let error;
    if (!value) {
      error = "Message is required";
    } else if (value.length > 2000) {
      error = "Message cannot be longer than 2000 characters";
    }
    return error;
  }

  function fixYahooEmail(values) {
    if (values.email.includes("yahoo")) {
      const splitEmail = values.email.split("@").slice(0, 1);
      const newEmail = `${splitEmail.toString()}@not-yahoo.com`;
      return {
        ...values,
        email: newEmail,
        message: (values.message += `\n correct email: ${values.email}`),
      };
    } else {
      return values;
    }
  }

  return (
    <ContactFormContainer>
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
          axios
            .post(
              "https://us-central1-mikem-contact-form.cloudfunctions.net/mikemioContactForm",
              fixYahooEmail(values)
            )
            .then(() => {
              resetForm({});
              setStatus(true);
              setSubmitting(false);
              setTimeout(() => setStatus(false), 2000);
              setTimeout(() => setSubmitStatus(true), 3600);
            })
            .catch((err) => {
              console.log(err);
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          validateForm,
          status,
        }) => (
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await validateForm();
              handleSubmit();
            }}
            style={{ position: "relative" }}
            noValidate
          >
            <ContactFormGroup>
              <InputField
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                iserror={`${errors.name && touched.name}`}
                validate={validateName}
              />
              <Label for="name">Name</Label>
              {errors.name && touched.name && (
                <ErrorMessage>{errors.name}</ErrorMessage>
              )}
            </ContactFormGroup>
            <ContactFormGroup>
              <InputField
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                iserror={`${errors.email && touched.email}`}
                validate={validateEmail}
              />
              <Label for="email">Email</Label>
              {errors.email && touched.email && (
                <ErrorMessage>{errors.email}</ErrorMessage>
              )}
            </ContactFormGroup>
            <ContactFormGroup>
              <InputTextArea
                component="textarea"
                type="text"
                id="message"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                maxLength="2000"
                iserror={`${errors.message && touched.message}`}
                validate={validateMessage}
              />
              <Label for="message">Message</Label>
              {errors.message && touched.message && (
                <ErrorMessage margin={10}>{errors.message}</ErrorMessage>
              )}
            </ContactFormGroup>
            <FormButton
              type="submit"
              disabled={isSubmitting}
              aria-label="submit button"
              onClick={() => {
                if (!Object.values(values).includes("")) {
                  setSubmitStatus(false);
                }
              }}
            >
              <AnimatedIcon drawn={status}>
                <SvgTick />
              </AnimatedIcon>
              {isSubmitting ? (
                <Spinner />
              ) : status ? (
                ""
              ) : submitStatus ? (
                "Submit"
              ) : (
                ""
              )}
            </FormButton>
          </form>
        )}
      </Formik>
    </ContactFormContainer>
  );
};

export default ContactForm;
