import React from "react";
import CallToAction from "./CallToAction";
import { HeroComponent, InnerHeroContainer } from "./library";
import Subtitle from "./Subtitle";
import Title from "./Title";

const Hero = ({
  title,
  section,
  sectionTitle,
  description,
  buttonContent,
  localUrl,
  handleClick,
  padding,
}) => {
  return (
    <HeroComponent padding={padding}>
      {title && <Title title={title} />}
      <InnerHeroContainer>
        <Subtitle
          section={section}
          title={sectionTitle}
          description={description}
        />
        {localUrl && (
          <CallToAction localUrl={localUrl} buttonContent={buttonContent} />
        )}
        {typeof handleClick === "function" && (
          <CallToAction buttonContent={buttonContent} onClick={handleClick} />
        )}
      </InnerHeroContainer>
    </HeroComponent>
  );
};

export default Hero;
