import * as React from "react";
import Layout from "../components/Layout.jsx";
import About from "../components/sections/About";
import Clients from "../components/sections/Clients";
import Contact from "../components/sections/Contact.jsx";
import Footer from "../components/sections/Footer.jsx";
import Skills from "../components/sections/Skills.jsx";
import SEO from "../components/Seo.jsx";
import "../styles/index.css";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Michael Mikucki" lang="en" />
      <About />
      <Clients />
      <Skills />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
