import React from "react";
import Hero from "../Hero";
import { ContactContainer, ContactInnerContainer } from "../library/Contact";
import CallToAction from "../CallToAction";
import ContactForm from "../ContactForm";
import { contact } from "../../utils/content";

function Contact() {
  return (
    <ContactContainer>
      <ContactInnerContainer>
        <Hero
          section={contact.section}
          sectionTitle={contact.sectionTitle}
          description={contact.description}
        />
        <CallToAction
          extUrl={"mailto:hi@mikem.io"}
          buttonContent={"hi@mikem.io"}
        />
        <CallToAction
          extUrl={"tel:00447507306633"}
          buttonContent={"0750 730 66 33"}
        />
      </ContactInnerContainer>
      <ContactForm />
    </ContactContainer>
  );
}

export default Contact;
