import React from "react";
import { SubtitleComponent } from "./library";

const Subtitle = ({ section, title, description }) => {
  return (
    <SubtitleComponent>
      {section && <h2>{section}</h2>}
      <h3>{title}</h3>
      <p>{description}</p>
    </SubtitleComponent>
  );
};

export default Subtitle;
