import React, { useState } from "react";
import Hero from "../Hero";
import { CardsContainer } from "../library/CardContainer";
import { SectionContainer } from "../library/Section";
import { skills } from "../../utils/content";
import { useDevice } from "../../utils/hooks";
import SkillCard from "../SkillCard";

function Skills() {
  const [card, setCard] = useState(null);
  const device = useDevice();

  const handleClick = (idx) => {
    if (device === "mobile") {
      setCard({ id: idx, type: `side=${idx}`, isOpen: true });
    } else {
      setCard({ id: idx, type: "main" });
    }
  };

  const setCardType = (idx) => {
    if (device === "mobile") {
      return `side=${idx}`;
    } else {
      return card?.id === idx ? card.type : `side=${idx}`;
    }
  };

  return (
    <SectionContainer color="light">
      <Hero
        section={skills.section}
        sectionTitle={skills.sectionTitle}
        description={skills.description}
      />
      <CardsContainer>
        {skills.cards.map((item, idx) => (
          <SkillCard
            key={idx}
            skillsList={item.skills}
            title={item.title}
            description={item.description}
            type={setCardType(idx)}
            isOpen={card?.id === idx && card.isOpen}
            onClick={() => handleClick(idx)}
            onClose={() => setCard(null)}
          />
        ))}
      </CardsContainer>
    </SectionContainer>
  );
}

export default Skills;
