import React, { useState } from "react";
import { introduction, about } from "../../utils/content";
import Hero from "../Hero";

const About = () => {
  const [state, setState] = useState(false);

  const handleClick = () => {
    setState(!state);
  };

  return (
    <Hero
      title={introduction.title}
      section={state ? about.section : introduction.section}
      sectionTitle={state ? null : introduction.sectionTitle}
      description={state ? about.description : introduction.description}
      buttonContent={state ? about.button : introduction.button}
      isOpen={state}
      handleClick={handleClick}
      padding={"50px"}
    />
  );
};

export default About;
