// import facebook from "../images/social/facebook.svg";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
import github from "../images/github.svg";
// import fsDeveloper from "../images/cards/full-stack-developer.svg";
// import frontDeveloper from "../images/cards/front-end-developer.svg";
// import designer from "../images/cards/designer.svg";

export const menuLinks = [
  // {
  //   text: "home",
  //   url: "/",
  // },
  // {
  //   text: "services",
  //   url: "/services",
  // },
  // {
  //   text: "projects",
  //   url: "/projects",
  // },
  // {
  //   text: "contact",
  //   url: "/#contact",
  // },
];

export const socialLinks = [
  //   {
  //     url: "https://www.facebook.com/Mikemio-100131214951642/",
  //     icon: facebook,
  //     title: "My facebook page link",
  //   },
  {
    url: "https://github.com/mike-em",
    icon: github,
    title: "My github link",
  },
  {
    url: "https://twitter.com/mikem_io",
    icon: twitter,
    title: "My twitter link",
  },
  {
    url: "https://www.instagram.com/mikem.io/",
    icon: instagram,
    title: "My instagram link",
  },
];

// export const cards = [
//   {
//     title: "Fullstack Developer.",
//     icon: fsDeveloper,
//     url: "/services",
//   },
//   {
//     title: "Front End Developer.",
//     icon: frontDeveloper,
//     url: "/services",
//   },
//   {
//     title: "UI / UX Designer.",
//     icon: designer,
//     url: "/services",
//   },
// ];
