import React from "react";
import Hero from "../Hero";
import {
  LogoLink,
  ClientLogoContainer,
  ClientLogo,
} from "../library/ClientLogo";
import { SectionContainer } from "../library/Section";
import { clients } from "../../utils/content";

const Clients = () => {
  return (
    <SectionContainer>
      <Hero
        section={clients.section}
        sectionTitle={clients.sectionTitle}
        description={clients.description}
      />
      <ClientLogoContainer>
        {clients.companies.map((item, idx) => (
          <LogoLink
            href={item.siteUrl}
            key={idx}
            target="_blank"
            rel="noreferrer"
          >
            <ClientLogo src={item.imageUrl} alt={item.name} />
          </LogoLink>
        ))}
      </ClientLogoContainer>
    </SectionContainer>
  );
};

export default Clients;
