import React from "react";

const getDevice = (windowWidth) => {
  if (windowWidth) {
    if (windowWidth < 640) {
      return "mobile";
    } else if (windowWidth < 1024) {
      return "tablet";
    } else if (windowWidth < 1536) {
      return "desktop";
    } else {
      return "4k";
    }
  } else {
    return undefined;
  }
};

export const useDevice = () => {
  const [windowSize, setWindowSize] = React.useState(getDevice(null));

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize(getDevice(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]);

  return windowSize;
};
