import React from "react";
import {
  FooterContainer,
  PrivacyIcon,
  SocialIcon,
  SocialIconsContainer,
} from "../library/Footer";
import { Logo } from "../library/Logo";
import { socialLinks } from "../../utils/links";
import { useDevice } from "../../utils/hooks";
import logo from "../../images/main-logo.svg";

const Footer = () => {
  const device = useDevice();
  return (
    <FooterContainer>
      <Logo img={logo} size={device === "mobile" ? "md" : null} />
      <p>
        <span>&copy;</span> mikem.io
      </p>
      <SocialIconsContainer>
        {socialLinks.map(({ icon, url, title }, idx) => (
          <a href={url} key={idx} target="blank">
            <SocialIcon icon={icon} title={title} />
          </a>
        ))}
        <PrivacyIcon to="/privacy-policy" title="privacy policy" />
      </SocialIconsContainer>
    </FooterContainer>
  );
};

export default Footer;
