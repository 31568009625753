import React from "react";
import { TitleComponent, Logo } from "./library";
import logo from "../images/main-logo.svg";

const Title = ({ title }) => {
  return (
    <TitleComponent>
      <Logo img={logo} />
      <h1>
        {title}
        <span>.</span>
      </h1>
    </TitleComponent>
  );
};

export default Title;
