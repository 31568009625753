import React from "react";
import { Card } from "./library";
import CallToAction from "./CallToAction";

const SkillCard = ({
  onClick,
  onClose,
  type,
  title,
  description,
  skillsList,
  isOpen,
}) => {
  return (
    <Card type={type}>
      <div>
        <h3>{title}</h3>
        <p>{description}</p>
        {(type === "main" || isOpen) && (
          <ul>
            {skillsList?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      <CallToAction
        onClick={type === "main" || isOpen ? onClose : onClick}
        fontSize={"xs"}
        buttonContent={type === "main" || isOpen ? "Close" : "See more"}
      />
    </Card>
  );
};

export default SkillCard;
